// const brochure1 = require('../../assets/portfolio/brochure/ew1.jpg')
// const brochure2 = require('../../assets/portfolio/brochure/Gaurik.jpg')
// const brochure3 = require('../../assets/portfolio/brochure/hl broch.jpg')
// const brochure4 = require('../../assets/portfolio/brochure/monsson offer.jpg')
// const brochure5 = require('../../assets/portfolio/brochure/p4.jpg')
// const brochure6 = require('../../assets/portfolio/brochure/prime one day pass4.jpg')
// const brochure7 = require('../../assets/portfolio/brochure/prime poster 1.jpg')
// const brochure8 = require('../../assets/portfolio/brochure/Prime poster 3.jpg')
// const brochure9 = require('../../assets/portfolio/brochure/X-pro coder.jpg')

// const gif1 = require('../../assets/portfolio/gif/gif2.gif')

// const logo1 = require('../../assets/portfolio/logo/abc.jpg')
// const logo2 = require('../../assets/portfolio/logo/BB logo concept.png')
// const logo3 = require('../../assets/portfolio/logo/c.png')
// const logo4 = require('../../assets/portfolio/logo/Coffe Logo.png')
// const logo5 = require('../../assets/portfolio/logo/eFANTOM.jpg')
// const logo6 = require('../../assets/portfolio/logo/heart logo new.png')
// const logo7 = require('../../assets/portfolio/logo/HOURLABS NEW LOGO.png')
// const logo8 = require('../../assets/portfolio/logo/lunch time.png')
// const logo9 = require('../../assets/portfolio/logo/Madhusudhan dairy logo.png')
// const logo10 = require('../../assets/portfolio/logo/N.png')
// const logo11= require('../../assets/portfolio/logo/pictusqueon111.png')
// const logo12= require('../../assets/portfolio/logo/prathem traders3.png')
// const logo13 = require('../../assets/portfolio/logo/Shine developers.png')
// const logo14 = require('../../assets/portfolio/logo/ssp.png')
// const logo15 = require('../../assets/portfolio/logo/SWAPPY LOGO.png')
// const logo16 = require('../../assets/portfolio/logo/The Hina Hirpara logo.png')


const web1 = require('../../assets/portfolio/web design/infinium solutionz web design.jpg')
const web2 = require('../../assets/portfolio/web design/new hl web final.jpg')
const web3 = require('../../assets/portfolio/web design/RTCAMP.jpg')
const web4 = require('../../assets/portfolio/web design/WEB DESIGN.jpg')



export const IMAGES =
[       
        //Web Desgin
        {
                src: web1,
                thumbnail: web1,
                tags: [{value: "Web Design"}],
                // caption: "After Rain (Jeshu John - designerspics.com)"
        },
        {
                src: web2,
                thumbnail: web2,
                tags: [{value: "Web Design"}],
                // caption: "After Rain (Jeshu John - designerspics.com)"
        },
        {
                src: web3,
                thumbnail: web3,
                tags: [{value: "Web Design"}],
                // caption: "After Rain (Jeshu John - designerspics.com)"
        },
        {
                src: web4,
                thumbnail: web4,
                tags: [{value: "Web Design"}],
                // caption: "After Rain (Jeshu John - designerspics.com)"
        },

        //Brochure Desgin
        // {
        //         src: brochure1,
        //         thumbnail: brochure1,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure2,
        //         thumbnail: brochure2,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure3,
        //         thumbnail: brochure3,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure4,
        //         thumbnail: brochure4,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure5,
        //         thumbnail: brochure5,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure6,
        //         thumbnail: brochure6,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure7,
        //         thumbnail: brochure7,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure8,
        //         thumbnail: brochure8,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: brochure9,
        //         thumbnail: brochure9,
        //         tags: [{value: "Brochure"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },

        //Gif
        // {
        //         src: gif1,
        //         thumbnail: gif1,
        //         tags: [{value: "Gif"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },

        //Logo
        // {
        //         src: logo1,
        //         thumbnail: logo1,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo2,
        //         thumbnail: logo2,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo3,
        //         thumbnail: logo3,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo4,
        //         thumbnail: logo4,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo5,
        //         thumbnail: logo5,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo6,
        //         thumbnail: logo6,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo7,
        //         thumbnail: logo7,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo8,
        //         thumbnail: logo8,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo9,
        //         thumbnail: logo9,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo10,
        //         thumbnail: logo10,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo11,
        //         thumbnail: logo11,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo12,
        //         thumbnail: logo12,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo13,
        //         thumbnail: logo13,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo14,
        //         thumbnail: logo14,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo15,
        //         thumbnail: logo15,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: logo16,
        //         thumbnail: logo16,
        //         tags: [{value: "Logo"}],
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // }

        
]