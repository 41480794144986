import React from 'react';

import DownArrow from '../../assets/down_arrow.png';

import serv1 from '../../assets/content/serv1.jpg';
import serv2 from '../../assets/content/serv2.jpg';
import serv3 from '../../assets/content/serv3.jpg';
import serv4 from '../../assets/content/serv4.jpg';

import servIcon1 from '../../assets/content/serv_icon1.png';
import servIcon2 from '../../assets/content/serv_icon2.png';
import servIcon3 from '../../assets/content/serv_icon3.png';
import servIcon4 from '../../assets/content/serv_icon4.png';

import $ from 'jquery';
import ReactEcharts from "echarts-for-react";

import * as MainComponents from './StrengthChart';

class Strength extends React.Component {
    closeStrength = () => {
        $(".strength_wrapper").animate({ "bottom": "-100%" }, 500);
        setTimeout(function () {
            $(".strength_wrapper").css("display", "none");
        }, 1000);
    }

    // Service Page Image Slides Js
    serviceImageClick = (e) => {
        if(!e.currentTarget.classList.contains('active')){
            $('div.services_slider_imgs img')[0].classList.remove('active');
			var target = $('div.services_slider_imgs .img_'+e.currentTarget.getAttribute('id'));
			var targetImg = target[0].outerHTML;
			target.remove();
			$('div.services_slider_imgs').prepend(targetImg);
			$('div.services_slider_imgs img:first').addClass('active');
			$('div.services_slider_box .img_click').removeClass('active');
			e.currentTarget.classList.add('active');
		}
	}

    render() {
        return (
            <div className="strength_wrapper pt_110">
                <div className="close_wrapper">
                    <a href="#!" onClick={this.closeStrength}>
                        <img src={DownArrow} alt="Down Arrow" />
                    </a>
                </div>

                <div className="container">
                    <div className="row">
                        {/* Service Slider Wrapper */}
                        <div className="services_slider_wrapper pb_50">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="heading_wrapper">
                                    <div className="heading">
                                        <h1>WHAT I DO</h1>
                                        <p>take a look</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="services_slider_imgs">
                                    <img className="img_1 active" src={serv1} alt="Service" />
                                    <img className="img_2" src={serv2} alt="Service" />
                                    <img className="img_3" src={serv3} alt="Service" />
                                    <img className="img_4" src={serv4} alt="Service" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="services_slider_box">
                                    <div className="services_slider_text img_click active" id="1" onClick={this.serviceImageClick}>
                                        <img src={servIcon1} alt="Service icon" />
                                        <h4>Website Design</h4>
                                        <p>It is a long established fact that a reader will be distracted</p>
                                    </div>
                                    <div className="services_slider_text img_click" id="2" onClick={this.serviceImageClick}>
                                        <img src={servIcon2} alt="Service icon" />
                                        <h4>Graphic Design</h4>
                                        <p>It is a long established fact that a reader will be distracted</p>
                                    </div>
                                    <div className="services_slider_text img_click" id="3" onClick={this.serviceImageClick}>
                                        <img src={servIcon3} alt="Service icon" />
                                        <h4>Logo Design</h4>
                                        <p>It is a long established fact that a reader will be distracted</p>
                                    </div>
                                    <div className="services_slider_text img_click" id="4" onClick={this.serviceImageClick}>
                                        <img src={servIcon4} alt="Service icon" />
                                        <h4>Web Development</h4>
                                        <p>It is a long established fact that a reader will be distracted</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        {/* Service Slider Wrapper */}
                        {/* <div className="couter_wrapper pb_50 pt_50">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter1} alt="Clients" />
                                    <h3 className="timer" data-from="0" data-to="95" data-speed="5000">95</h3>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter2} alt="Projects" />
                                    <h3 className="timer" data-from="0" data-to="165" data-speed="5000">165</h3>
                                    <p>Project Done</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter3} alt="Awards" />
                                    <h3 className="timer" data-from="0" data-to="16" data-speed="5000">16</h3>
                                    <p>Award Won</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div> */}

                        {/* Skills Wrapper */}
                        <div className="skills_wrapper pb_50 pt_50">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="heading_wrapper">
                                    <div className="heading">
                                        <h1>MY SKILLS</h1>
                                        <p>Expert In</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="skills_chart">
                                    {/* <h4 class="text-center">Technical Skills</h4> */}
                                    <ReactEcharts option={MainComponents.option_radar} style={MainComponents.style} className="radar-chart" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="skills_chart">
                                    {/* <h4 class="text-center">Software Skills</h4> */}
                                    <ReactEcharts option={MainComponents.option_pie} style={MainComponents.style} className="pie-chart" />
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>

                {/* Service Slider Wrapper */}
                {/* <div className="couter_wrapper pb_50 pt_50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter1} alt="Clients" />
                                    <h3 className="timer" data-from="0" data-to="95" data-speed="5000">95</h3>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter2} alt="Projects" />
                                    <h3 className="timer" data-from="0" data-to="165" data-speed="5000">165</h3>
                                    <p>Project Done</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="counter_box">
                                    <img src={counter3} alt="Awards" />
                                    <h3 className="timer" data-from="0" data-to="16" data-speed="5000">16</h3>
                                    <p>Award Won</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Strength;