import React from 'react';
import LeftArrow from '../../assets/right_arrow.png';
import $ from 'jquery';
import Gallery from 'react-grid-gallery';

import { IMAGES } from './PortfolioImages'


class Portfolio extends React.Component {
    closeAbout = () => {
        $(".portfolio_wrapper").animate({ "left": "-100%" }, 500);
        setTimeout(function () {
            $(".portfolio_wrapper").css("display", "none");
        }, 1000);
    }
    render() {
        return (
            <div className="portfolio_wrapper pt_110 pb_50">
                <div className="close_wrapper">
                    <a href="#!" onClick={this.closeAbout}>
                        <img src={LeftArrow} alt="Right Arrow" />
                    </a>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="heading_wrapper">
                                <div className="heading">
                                    <h1>portfolio</h1>
                                    <p>my work</p>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="portfolio_box">
                            <Gallery images={IMAGES} enableImageSelection={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Portfolio;