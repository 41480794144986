import React from 'react';
import LeftArrow from '../../assets/left_arrow.png';
import $ from 'jquery';
import * as emailjs from 'emailjs-com';

class Contact extends React.Component {
    state = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }
    
    handleSubmit(e) {
        debugger;;
        e.preventDefault()
        const { name, email, subject, message } = this.state
        let templateParams = {
          from_name: name,
          from_email: email,
          to_name: 'niravoza.work@gmail.com',
          subject: subject,
          message_html: message,
        }
        emailjs.send(
          'gmail',
          'template_D4XFUZa5',
           templateParams,
          'user_aj83bxdLGlmI542Bz7xs3'
        )
        alert('Mail sent successfully!!');
        // swal({
        //     title: "Good job!",
        //     text: "You clicked the button!",
        //     icon: "success",
        // });
        this.resetForm()
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }

    closeAbout = () => {
        $(".contact_wrapper").animate({ "right": "-100%" }, 500);
        setTimeout(function () {
            $(".contact_wrapper").css("display", "none");
        }, 1000);
    }
    render() {
        return (
            <div className="contact_wrapper pt_110 pb_50">
                <div className="close_wrapper">
                    <a href="#!" onClick={this.closeAbout}>
                        <img src={LeftArrow} alt="Left Arrow" />
                    </a>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="heading_wrapper">
                                <div className="heading">
                                    <h1>get in touch</h1>
                                    <p>contact us</p>
                                </div>
                            </div>
                        </div>
                        <div className="contact_box">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="contact_info">
                                    <h1>How we can help you ?</h1>
                                    <div className="contact_form">
                                        <div className="row">
                                            <form onSubmit={this.handleSubmit.bind(this)}>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <input 
                                                        type="text" 
                                                        name="name" 
                                                        value={this.state.name} 
                                                        onChange={this.handleChange.bind(this, 'name')}
                                                        placeholder="Your Name" 
                                                        className="require" 
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <input 
                                                        type="text" 
                                                        name="email" 
                                                        value={this.state.email}
                                                        onChange={this.handleChange.bind(this, 'email')}
                                                        placeholder="Your Email" 
                                                        className="require" 
                                                        data-valid="email" 
                                                        data-error="Email should be valid." 
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <input 
                                                        type="text" 
                                                        name="subject" 
                                                        value={this.state.subject}
                                                        onChange={this.handleChange.bind(this, 'subject')}
                                                        placeholder="Subject" 
                                                        className="require" 
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <textarea 
                                                        rows="4" 
                                                        name="message" 
                                                        placeholder="Message" 
                                                        className="require"
                                                        required
                                                        value={this.state.message}
                                                        onChange={this.handleChange.bind(this, 'message')}>
                                                    </textarea>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="response"></div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <button type="submit" className="btn theme_btn mr_5">submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="contact_map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d916.4770240039262!2d72.64875398810149!3d23.246431181276247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2b8f80c89ae3%3A0x5df737a2aaeaca0d!2sPicturesqueON!5e0!3m2!1sen!2sin!4v1567922465460!5m2!1sen!2sin" allowFullScreen=""></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="contact_details">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="contact_details_box details_box1">
                                    <h4>Phone</h4>
                                    <p>+91 8153844379<br />+91 9978098842</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="contact_details_box details_box2">
                                    <h4>Email</h4>
                                    <p><a href="#">niravoza.work@gmail.com</a></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="contact_details_box details_box3">
                                    <h4>Address</h4>
                                    <p>162, Anand Nagar,<br />Sector 27, Gandhinagar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;