import React from 'react';
import UpArrow from '../../assets/up_arrow.png';
import AboutImg from '../../assets/content/ab-img.png';
import cv from '../../assets/Compress_Resume_Nirav.pdf';
import $ from 'jquery';

class About extends React.Component {
    closeAbout = () => {
        $(".about_wrapper").animate({ "top": "-100%" }, 500);
        setTimeout(function () {
            $(".about_wrapper").css("display", "none");
        }, 1000);
    }

    downloadRandomImage = () => {
        fetch(cv)
            .then(response => {
                const filename = response.headers.get('Content-Disposition');
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Nirav Oza';
                    a.click();
                });
                console.log('Something Wrong!');
            });
    }

    render() {
        return (
            <div className="about_wrapper pt_110">
                <div className="close_wrapper">
                    <a href="#!" onClick={this.closeAbout}>
                        <img src={UpArrow} alt="Down Arrow" />
                    </a>
                </div>
                <div className="container">
                    <div className="row">
                        {/* About Information */}
                        <div className="about_info_wrapper pb_50">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="about_img">
                                    <img src={ AboutImg } alt="About"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="heading_wrapper_1">
                                    <div className="heading pt_50">
                                        <h1>About ME</h1>
                                        <p>WHo AM i</p>
                                    </div>
                                </div>
                                <div className="about_details">
                                    <p>
                                        I am a Front-end Developer, producing high quality responsive websites and exceptional user experience
                                        I create responsive websites that allow the user to experience your website in the best and most appropriate way suited to the device they are using.
                                    </p>
                                    <button type="button" className="btn theme_btn mr_5" onClick={this.downloadRandomImage}>Download Resume</button>
                                    {/* <button type="button" className="btn theme_btn ml_5">Hire Me</button> */}
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        
                        {/* Education Information */}
                        <div className="about_education_wrapper pb_50">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="heading_wrapper">
                                    <div className="heading">
                                        <h1>EDUCTION</h1>
                                        <p>LEARNING</p>
                                    </div>
                                </div>
                                <div className="about_learnsection">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-offset-6 col-md-offset-6 col-sm-offset-0 col-xs-offset-0">
                                            <div className="about_learnbox_right">
                                                <div className="about_learnbox_year">
                                                    <h1>2012</h1>
                                                </div>
                                                <div className="about_learnbox_info">
                                                    <h4>10<sup>th</sup> (Secondary)</h4>
                                                    <span>GSEB Board</span>
                                                    <span>R.C. Patel School, Gandhinagar</span>
                                                    <p>Percentage: 70%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="about_learnbox_left">
                                                <div className="about_learnbox_year">
                                                    <h1>2014</h1>
                                                </div>
                                                <div className="about_learnbox_info">
                                                    <h4>12<sup>th</sup> (Higher Secondary)</h4>
                                                    <span>GSEB Board</span>
                                                    <span>Ved International School, Gandhinagar</span>
                                                    <p>Percentage: 60%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-offset-6 col-md-offset-6 col-sm-offset-0 col-xs-offset-0">
                                            <div className="about_learnbox_right">
                                                <div className="about_learnbox_year">
                                                    <h1>2018</h1>
                                                </div>
                                                <div className="about_learnbox_info">
                                                    <h4>B.E. Computer Engineering</h4>
                                                    <span>LDRP - ITR, Gandhinagar</span>
                                                    <p>CGPA: 7.01 / 10</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Experience Information */}
                        <div className="about_experience_wrapper bottompadder60">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="heading_wrapper">
                                    <div className="heading">
                                        <h1>Experience</h1>
                                        <p>involvement</p>
                                    </div>
                                </div>
                                <div className="about_experience">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="about_experiencebox">
                                                <div className="about_experience_year">
                                                    <h1>2018</h1>
                                                    <h4>May 2018 to May 2020</h4>
                                                </div>
                                                <div className="about_experience_info">
                                                    <h4>Ui/Ux Developer</h4>
                                                    <span>Amnex Infotechnologies Pvt Ltd</span>
                                                    <p>Convert Ui design to HTMl, Make Responsive Dashboard</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="about_experiencebox">
                                                <div className="about_experience_year">
                                                    <h1>2020</h1>
                                                    <h4>Jun 2020 to Jan 2021</h4>
                                                </div>
                                                <div className="about_experience_info">
                                                    <h4>Front-end Developer</h4>
                                                    <span>eFANTOM Information Technology & Services</span>
                                                    <p>Convert Ui design to HTMl, Make Responsive Dashboard</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="about_experiencebox">
                                                <div className="about_experience_year">
                                                    <h1>2021</h1>
                                                    <h4>Feb 2021 to Present</h4>
                                                </div>
                                                <div className="about_experience_info">
                                                    <h4>Ui Developer</h4>
                                                    <span>Asite Solutions Pvt. Ltd.</span>
                                                    <p>Convert Ui design to HTMl, Make Responsive Dashboard</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;