//Chart style
export const style = {
    height: "500px",
    width: "100%"
};


// Radar Chart options
export let option_radar = {
    title: {
        text: 'Technical Skills',
        y: 'top',
        x: 'center',
        textStyle: {
            fontWeight: '600',
            fontSize: 18,
            color: '#fff'
        }
    },
    // "normal": {
    //     "top": 200,
    //     "left": 300,
    //     "width": 500,
    //     "height": 400,
    //     "zIndex": 6,
    //     "backgroundColor": ""
    // },
    "color": ["rgb(93, 208, 2)"],
    "tooltip": {
        "show": true,
        "trigger": "item"
    },
    "legend": {
        "show": false,
        "icon": "circle",
        "left": "43%",
        "top": "90%",
        "orient": "horizontal",
        "textStyle": {
            "fontSize": 16,
            "color": "#fff"
        },
        "data": ["正常指标", "实际指标"]
    },
    "radar": {
        "center": ["50%", "50%"],
        "radius": "62%",
        "startAngle": 90,
        "splitNumber": 5,
        "shape": "polygon",
        name: {
                textStyle: {
                    color: '#fff',
                    fontSize: 15
                }
            },
         splitArea: {
                show: true,
                areaStyle: {
                    color: '#0d6dba',
                    opacity: 0.1
                }
            },
        "axisLabel": {
            "show": false,
            "fontSize": 18,
            "color": "#fff",
            "fontWeight": "normal"
        },
         axisLine: {
                show: true,
                lineStyle: {
                    color: '#4f8bbe',
                    opacity: 1
                }
            },
       splitLine: {

                lineStyle: {
                    color: '#4f8bbe',
                    opacity: 0.5
                }
            },
        "indicator": [{
            "name": "HTML",
            "max": 100
        }, {
            "name": "CSS",
            "max": 100
        }, {
            "name": "Java Script",
            "max": 100
        }, {
            "name": "jQuery",
            "max": 100
        }, {
            "name": "React JS",
            "max": 100
        }, {
            "name": "DataTable JS",
            "max": 100
        }, {
            "name": "Kendo UI",
            "max": 100
        }, {
            "name": "Google Map API",
            "max": 100
        }, {
            "name": "Echarts JS",
            "max": 100
        }, {
            "name": "D3 Js",
            "max": 100
        }, {
            "name": "Angular",
            "max": 100
        }, {
            "name": "Electron",
            "max": 100
        }, ] 
    },
    "series": [{
        "name": "Technical Skills",
        "type": "radar",
        "symbol": "circle",
        "symbolSize": 10,
        "areaStyle": {
            "normal": {
                "color": "rgba(93, 208, 2, 0.4)"
            }
        },
        itemStyle:{
            color:'rgba(93, 208, 2, 1)',
            borderColor:'rgba(93, 208, 2, 0.3)',
            borderWidth:10,
        },
        "lineStyle": {
            "normal": {
                "type": "dashed",
                
                "color": "rgba(93, 208, 2, 1)",
                "width": 2
            }
        },
        "data": [
            [98, 98, 70, 90, 60, 60, 70, 80, 90, 75, 40, 40]
        ]
    }]
};



// Pie Chart Option
export let option_pie = {
    title: {
        text: 'Software Skills',
        y: 'top',
        x: 'center',
        textStyle: {
            fontWeight: '600',
            fontSize: 18,
            color: '#fff'
        }
    },
    legend: {
      orient: 'horizontal',
      show: true,
      x: 'center',
      bottom: 30,
      itemWidth: 3,
      itemHeight: 35,
      itemGap: 20,
      textStyle: {
        color: '#7a8c9f',
        fontSize: 16,
        lineHeight: 20,
        rich: {
          percent: {
            color: '#fff',
            fontSize: 18,
          },
        },
      },
      formatter: name => {
        switch (name) {
          case 'Photoshop':
            return (
              'Photoshop\r\n{percent|90%} '
            );
          case 'Illustrator':
            return (
              'Illustrator\r\n{percent|60%} '
            );
          case 'CorelDRAW':
            return (
              'CorelDRAW\r\n{percent|40%} '
            );
          default:
            break;
        }
      },
    },
    tooltip: {
      show: true,
    },
     series: [
      {
        type: 'pie',
        radius: ['50%', '60%'],
        center: ['50%', '50%'],
        hoverAnimation: true,
        z: 10,
        label: {
          show: false,
          position: 'center',
          formatter: (params) => {
            return params.value;
          },
          rich: {
            total: {
              fontSize: 30,
              color: '#fff',
            },
          },
          color: '#7a8c9f',
          fontSize: 16,
          lineHeight: 30,
        },
        data: [
          {
            value: 90,
            name: 'Photoshop',
            itemStyle: {
              color: '#0286ff',
            },
          },
          {
            value: 60,
            name: 'Illustrator',
            itemStyle: {
              color: '#ffd302',
            },
          },
          {
            value: 40,
            name: 'CorelDRAW',
            itemStyle: {
              color: '#5dd002',
            },
          },
        ],
        labelLine: {
          show: false,
        },
      },
      {
        type: 'pie',
        radius: ['39%', '49%'],
        center: ['50%', '50%'],
        hoverAnimation: false,
        label: {
          show: false,
        },
        data: [
            {
              value: 90,
              name: 'Photoshop',
              itemStyle: {
                color: '#0286ff',
                opacity: 0.4,
              },
            },
            {
              value: 60,
              name: 'Illustrator',
              itemStyle: {
                color: '#ffd302',
                opacity: 0.4,
              },
            },
            {
              value: 40,
              name: 'CorelDRAW',
              itemStyle: {
                color: '#5dd002',
                opacity: 0.4,
              },
            },
        ],
        labelLine: {
          show: false,
        },
      },
      {
        type: 'pie',
        radius: ['28%', '38%'],
        center: ['50%', '50%'],
        hoverAnimation: false,
        label: {
          show: false,
        },
        data: [
            {
              value: 90,
              name: 'Photoshop',
              itemStyle: {
                color: '#0286ff',
                opacity: 0.1,
              },
            },
            {
              value: 60,
              name: 'Illustrator',
              itemStyle: {
                color: '#ffd302',
                opacity: 0.1,
              },
            },
            {
              value: 40,
              name: 'CorelDRAW',
              itemStyle: {
                color: '#5dd002',
                opacity: 0.1,
              },
            },
        ],
        labelLine: {
          show: false,
        },
      },
    ],
  
};