import React from 'react';
import $ from 'jquery';

class Menu extends React.Component {

    openAbout = () => {
        $(".about_wrapper").css("display", "block");
        $(".about_wrapper").animate({"top" : 0 }, 500);
    }

    openContact = () => {
        $(".contact_wrapper").css("display", "block");
        $(".contact_wrapper").animate({"right" : 0 }, 500);
    }

    openStrength = () => {
        $(".strength_wrapper").css("display", "block");
        $(".strength_wrapper").animate({"bottom" : 0 }, 500);
    }

    openPortfolio = () => {
        $(".portfolio_wrapper").css("display", "block");
        $(".portfolio_wrapper").animate({"left" : 0 }, 500);
    }

    render() {
        return (
            <div className="menu_wrapper">
                <a href="#!" className="top_menu" onClick={this.openAbout}>about</a>
                <a href="#!" className="right_menu" onClick={this.openContact}>contact</a>
                <a href="#!" className="bottom_menu" onClick={this.openStrength}>strength</a>
                <a href="#!" className="left_menu" onClick={this.openPortfolio}>portfolio</a>
            </div>
        )
    }
}

export default Menu