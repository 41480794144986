import React from 'react';
import logo from '../../assets/logo.png'

class Logo extends React.Component {
    render() {
        return (
            <div className="logo_wrapper">
                <a href="#!"><img src={ logo } alt="Logo"/></a>
            </div>
        )
    }
}

export default Logo;