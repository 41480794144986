import React from 'react';
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Contact from './Components/Contact/Contact'
import Strength from './Components/Strength/Strength'
import Portfolio from './Components/Portfolio/Portfolio'

import './css/App.css';
import './css/animate.css';

function App() {
  return (
    <div className="main_wrapper">
        {/* Home Section */}
        <Home></Home>

        {/* About Section */}
        <About></About>

        {/* Contact Section */}
        <Contact></Contact>

        {/* Strength Section */}
        <Strength></Strength>

        {/* Portfolio Section */}
        <Portfolio></Portfolio>
    </div>
  );
}

export default App;
