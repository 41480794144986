import React from 'react';
import Logo from '../Logo/Logo'
// import Video from '../../assets/video/videoplayback.mp4'
import Menu from '../Menu/Menu'

class Home extends React.Component {
    render() {
        return (
            <div className="home_wrapper">
                <Logo></Logo>
                {/* <video autoPlay loop muted className="video_box">
                    <source src={ Video } type="video/mp4" />
                </video> */}
                <Menu></Menu>
                <div className="container h100">
                    <div className="text_wrapper">
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8 col-lg-offset-1 col-md-offset-1 col-sm-offset-2 col-xs-offset-2">
                                <h1>I`m Nirav Oza</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;